import { gql } from '@apollo/client';

const getVehicleQuery = gql`
  query ($vehicleId: String!) {
    getVehicle(input: $vehicleId) {
      brand
      bodyType
      color
      confort
      description
      doors
      equipment
      exterior
      exteriorColor
      fuelType
      id
      interiorColor
      isPublished
      kms
      location
      model
      motor
      pictures
      previewPictures
      price
      security
      separateUrl
      state
      stereo
      transmission
      version
      year
      fifthWheel
      rearAxlesCapacity
      cab
      engineUsage
      truckClass
      certified
      seller {
        id
        name
        address
        lat
        lon
        logo
        whatsappNumber
        currency
        phone
        directMessageEnabled
        financingComponent
      }
    }
  }
`;

export default getVehicleQuery;
